import React from 'react';
import { graphql, Link } from 'gatsby';
import ProductGrid from '../components/ProductGrid';
import Seo from '../components/SEO';

function ProductsPage({ data: { products } }) {
  if (!products) return null;

  return (
    <React.Fragment>
      <Seo pageTitle="products" />
      <div
        className="flex flex-row items-center justify-between w-full mt-16 text-white bg-primary md:mt-20 lg:mt-12"
      >
        <h1 className="p-4 text-4xl font-bold md:text-4xl">all in</h1>
        <Link className="p-4" to="/en/">
          see all
        </Link>
      </div>
      <ProductGrid products={products.nodes} />
    </React.Fragment>
  );
}

export const pageQuery = graphql`
  query ProductsQuery {
    products: allGraphCmsProduct {
      nodes {
        id
        name
        organic
        inStock
        newIn
        price
        remoteId
        images {
          localFile {
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
                formats: [AUTO]
              )
            }
          }
        }
      }
    }
  }
`;

export default ProductsPage;
